import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table/react-table.css";
import 'react-image-lightbox/style.css';
import "video.js/dist/video-js.css";
import cookie from 'react-cookies'
import { urlAPIAccess, urlAPIAccessImg, proxyurl } from "./helpers/API";
import { isMultiColorActive, defaultColor, themeColorStorageKey, isDarkSwitchActive } from "./constants/defaultValues";
import data from './helpers/thema.json';
let cor = "";

//localStorage.setItem("__theme_color", data.thema);
let logos = data.logo;

const f = (!async function () {
  cor = await fetch(`${proxyurl}${urlAPIAccess}/tokenacesso/geturl/${window.location.host.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  ).then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.id) {
        cookie.remove('imagem', { path: '/' })
        const favicon = document.getElementById("favicon")
        const textP = document.getElementById("textprincipal")
        textP.innerHTML = responseJson.Empresa.nameApp;
        favicon.href = proxyurl + urlAPIAccessImg + "/" + responseJson.Empresa.imgFav
        return responseJson.Empresa.corPadrao
      } else {
        return "light.purple"
      }
    })
    .catch((error) => {
      console.log(error)
    });

  //console.log(cor)
  //localStorage.setItem(themeColorStorageKey, cor);

  return true;
}())

cookie.save('imagem', logos, { path: '/' });

//let teste = foi;

/*const color =
  (isMultiColorActive||isDarkSwitchActive ) && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;*/


//const color = localStorage.getItem(themeColorStorageKey);


let render = () => {
  import('./assets/css/sass/themes/gogo.' + data.thema + '.scss').then(x => {
    require('./AppRenderer');
  });
};
render();